
import { mixins } from 'vue-class-component';
import RandomizeMixin from '@/mixins/RandomizeMixin';

export default class Connect extends mixins(RandomizeMixin) {
  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    action: {
      value: 'Contact',
      options: [
        'Contact',
        'Summon',
        'Hire',
        'Encounter',
        'Reach out and touch',
        'Seek the company of',
        'Find solace in',
        'Tell it to',
        'Seek out',
        'Bring forth',
        'Shower praise on',
        'Rage at',
        'Split the profits with',
        'Share the wealth with',
        'Whine to',
        'Embrace',
        'Call upon',
        'Thank',
        'Send gifts to',
        'Don\'t anger',
      ],
    },
    entity: {
      value: 'Ben',
      options: [
        'Ben',
        'a magician',
        'a wizard',
        'a legend',
        'the darkness',
        'a demon',
        'an angel',
        'a replicant',
        'a star coder',
        'your new friend',
        'a twisted mind',
        'destiny',
        'your future',
        'greatness',
        'an award winner',
        'experience',
        'the answer',
        'he who talks to himself',
        'the keymaster',
        'he who has your back',
        'the man with a plan',
        'an inter-dimensional being',
      ],
    },
  }

  // eslint-disable-next-line
  mounted(): void {
    const elem = document.createElement('script');
    elem.setAttribute('src', '//embed.typeform.com/next/embed.js');
    document.head.appendChild(elem);
  }
}
